<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Icon *
                </label>
                <single-upload-file 
                    @responseURL="setFileUrl"
                    :current_file="form.icon_url">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Parent
                </label>
                <Dropdown v-model="form.selected_parent" :options="all_menus" optionLabel="name" placeholder="Pilih Menu Parent" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Label Menu *
                </label>
                <InputText type="text" placeholder="Label" v-model="form.label" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Menu *
                </label>
                <InputText type="text" placeholder="Nama Menu" v-model="form.name" :disabled="form.is_submit" />
            </div>
            <div class="grid">
                <div class="col-12 md:col-4">
                    <div class="field">
                        <label class="text-black text-14 roboto-medium mb-3">
                            Status
                        </label>
                        <div class="flex align-items-center">
                            <InputSwitch v-model="form.status_toggle" :disabled="form.is_submit" />
                            <label class="text-black opacity-60 ml-2">
                                Aktif
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <label class="text-black text-14 roboto-medium mb-3">
                            Modal
                        </label>
                        <div class="flex align-items-center">
                            <InputSwitch v-model="form.modal_toggle" :disabled="form.is_submit" />
                            <label class="text-black opacity-60 ml-2">
                                Modal
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <label class="text-black text-14 roboto-medium mb-3">
                            Favorite
                        </label>
                        <div class="flex align-items-center">
                            <InputSwitch v-model="form.favorite_toggle" :disabled="form.is_submit" />
                            <label class="text-black opacity-60 ml-2">
                                Favorite
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SingleUploadFile from '@/components/SingleUploadFile.vue'

export default {
    props: ['data', 'all_menus'],
    components: {
        SingleUploadFile,
    },
    data() {
        return {
            form: {
                id: null,
                icon_url: '',
                label: '',
                name: '',
                status_toggle: false,
                status: 0,
                modal_toggle: false,
                is_modal: 0,
                favorite_toggle: false,
                is_favorite: 0,
                selected_parent: {},
                parent_id: '',
                parent_name: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.parent_name = this.form.selected_parent ? this.form.selected_parent['name'] : ''
                this.form.parent_id = this.form.selected_parent ? this.form.selected_parent['id'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_parent = this.all_menus.find(obj => obj.id == this.form.parent_id);
            this.form.status_toggle = this.form.status ? true : false
            this.form.modal_toggle = this.form.is_modal ? true : false
            this.form.favorite_toggle = this.form.is_favorite ? true : false
        }
    },
    methods: {
        setFileUrl(url) {
            this.form.icon_url = url
        },
    },
}
</script>

<style lang="scss" scoped></style>
